import axios from "axios";
import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";

function Register(props) {
  const [formVisible, setFormVisible] = useState(false);
  const [thanksVisible, setThanksVisible] = useState(false);
  const [regVisible, setRegVisible] = useState(true);
  const handleRegisterClick = () => {
    setFormVisible(true);
  };
  const [active, setActive] = useState(1);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("email", e.target[0].value);
    data.append("is_active", active);
    axios({
      method: "POST",
      url: "https://judilogy.mn/api/guest",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        setFormVisible(false);
        setThanksVisible(true);
        setRegVisible(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <div className="newarivals">
      <div className="linear-overlay"></div>
      <Container>
        <Row className="reg-body">
          <Col sm={12} xl={6}>
            <Card className="reg-card">
              <Card.Body>
                <Card.Title className="left-ar">
                  <h4 className="reg-title">Имэйл бүртгүүлэх</h4>
                </Card.Title>
                <Card.Text className="left-ar">
                  <h4>
                    Хууль, эрх зүйн орчны сонирхолтой мэдээ, мэдээллийг и-мэйл
                    хаягаар хүлээн авахыг хүсвэл бүртгүүлээрэй.
                  </h4>
                </Card.Text>
              </Card.Body>

              <div className="r-btn">
                {!formVisible && (
                  <button
                    className={regVisible ? "register-btn" : "d-none"}
                    onClick={handleRegisterClick}
                  >
                    Бүртгүүлэх
                  </button>
                )}
              </div>

              {formVisible && (
                <form
                  action=""
                  className="register-form"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="email"
                    placeholder="Имэйл хаягаа бүртгүүлнэ үү!"
                  />
                  <button className="s-btn" type="submit">
                    <img src="/send-mail.png" alt="" />
                  </button>
                </form>
              )}

              <div className="t-btn">
                <button className={thanksVisible ? "thanks-btn" : "d-none"}>
                  Баярлалаа <img src="/smile.png" alt="" />
                </button>
              </div>
            </Card>
          </Col>
          <Col sm={12} xl={6} className="right-ar">
            {" "}
            <Card className="right-ar-card">
              <Card.Body>
                <Card.Text>
                  <h3 className="reg-alarm">Санамж</h3>
                  <h4>
                    Judilogy багийн бэлтгэн гаргасан Хэргийн мэдээллийн хуудас,
                    подкаст, судалгаа өгүүлэл, мэдээ мэдээлэл, нийтлэл зэрэг нь
                    мэргэжлийн хууль зүйн зөвлөх үйлчилгээг орлохгүй бөгөөд
                    зөвхөн мэдээлэл хүргэх зорилготой болно. Байршсан мэдээ,
                    мэдээлэл нь тухайн нийтлэгдсэн хугацаандаа үнэн зөв бөгөөд
                    хамгийн сүүлийн үеийн мэдээлэл биш байх боломжтой.
                  </h4>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Register;
