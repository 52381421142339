import authHeaders from "./auth-header";
const base_url = "https://judilogy.mn";

const loginUser = async (credentials) => {
  return await fetch(`${base_url}/api/user/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email: credentials.email,
      password: credentials.password,
    }),
  });
};
const registerUser = async (credentials) => {
  return await fetch(`${base_url}/api/user/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": authHeaders(),
    },
    body: JSON.stringify({
      email: credentials.email,
      username: credentials.username,
      password: credentials.password
    }),
  });
};

const getAllUser = async () => {
  return await fetch(`${base_url}/api/user`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": authHeaders(),
    },
  });
};


export const userService = {
  loginUser,
  getAllUser,
  registerUser
};
