import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Container, Modal, Navbar } from "react-bootstrap";
import Footer from "./Footer";
import { useLocation, NavLink } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import axios from "axios";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

function ResearchDetail(props) {
  const location = useLocation();
  const { state } = location;
  const data = state.from;
  const data1 = state.from1;
  const data2 = state.from2;
  const data3 = state.from3;
  const path = location.pathname;

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  const [rsId, setRsId] = useState({
    id: "",
    file: "",
  });

  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/research/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        setRsId(...data);
      });
  };
  useEffect(() => {
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    window.addEventListener("contextmenu", disableRightClick);

    return () => {
      window.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);
  useEffect(() => {
    const handleBeforeCapture = (e) => {
      e.preventDefault();
      alert("Taking screenshots is not allowed.");
    };

    window.addEventListener("beforeprint", handleBeforeCapture);

    return () => {
      window.removeEventListener("beforeprint", handleBeforeCapture);
    };
  }, []);

  return (
    <div>
      <div className="header-bg">
        {" "}
        <Header />
      </div>

      <Container>
        <ul className="breadcrumb">
          <a href="/" className="breadcrumb__item breadcrumb__item-firstChild">
            {" "}
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">Нүүр</span>
              </span>
            </li>
          </a>

          <NavLink
            to={{ pathname: "/library" }}
            state={{
              from: data1,
            }}
            className="breadcrumb__item"
          >
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">Судалгааны сан</span>
              </span>
            </li>
          </NavLink>
          <NavLink
            to={{ pathname: "/rs" }}
            state={{
              from: data1,
            }}
            className={path === "/rs" ? "selected_title" : "breadcrumb__item"}
          >
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">{data1}</span>
              </span>
            </li>
          </NavLink>
        </ul>

        <h3 className="rs-title">{data1}</h3>

        <div className="rs-body-text">
          <p className="rs-body-text">{data2}</p>

          <p>
            Эх сурвалж: <a href="/">Lorem, ipsum dolor.</a>
          </p>
          <p>
            Файл нээх:{" "}
            <span
              onClick={() => {
                setShow(true);
                showDetail(data);
              }}
            >
              {data3}
            </span>
          </p>
        </div>
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <Navbar.Brand href="/">
                <img src="/judilogy_logo.png" alt="" className="logo" />
              </Navbar.Brand>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pdf-view">
            <div className="pdf-div">
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <Document
                file={`https://judilogy.mn/api/files/research/${data3}`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page) => {
                    return (
                      <Page
                        pageNumber={page}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                      />
                    );
                  })}
              </Document>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
      <Footer />
    </div>
  );
}

export default ResearchDetail;
