import React, { useEffect, useState } from "react";
import { Card, CardGroup, Container, Tab, Tabs } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa6";
import { services } from "../services/services";
import { NavLink } from "react-router-dom";
function Docs(props) {
  const [docs, setDocs] = useState([]);
  useEffect(() => {
    services
      .getDocs()
      .then((res) => res.json())
      .then((res) => setDocs(res));
  }, []);
  const sortedData = docs.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // Select the latest 3 data items
  const latestDataI = sortedData
    .filter((a) => a.docs_type == "Иргэний")
    .slice(0, 3);
  const latestDataE = sortedData
    .filter((a) => a.docs_type == "Эрүүгийн")
    .slice(0, 3);
  const latestDataZ = sortedData
    .filter((a) => a.docs_type == "Захиргааны")
    .slice(0, 3);

  const calculateTimePassed = (createdAt) => {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);
    const timeDifference = currentDate.getTime() - createdDate.getTime();

    // Calculate time difference in milliseconds to years, months, days, hours, minutes, and seconds
    const yearsDiff = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
    const monthsDiff = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24 * 365)) /
        (1000 * 60 * 60 * 24 * 30)
    );
    const daysDiff = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
    );

    return ` ${daysDiff} өдрийн өмнө`;
  };
  return (
    <div className="docs" id="docs-slide">
      <Container>
        <h3 className="title-section mb-4">Хэргийн мэдээллийн хуудас</h3>
        <Tabs
          defaultActiveKey="home"
          id="justify-tab-example"
          className="m-2 p-2"
          justify
        >
          <Tab eventKey="home" title="Иргэний хэрэг">
            <CardGroup>
              {latestDataI
                .filter((n) => (n.is_active = 1))
                .map((e) => (
                  <Card>
                    <Card.Img
                      variant="top"
                      src={`https://judilogy.mn/api/doc/image/${e.image_url}`}
                    />
                    <Card.Body>
                      <Card.Title>{e.title}</Card.Title>
                      <Card.Text>{e.description}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">
                        {calculateTimePassed(e.created_at)}
                      </small>
                    </Card.Footer>
                  </Card>
                ))}
            </CardGroup>
            <div className="more-btn">
              <NavLink
                to={{ pathname: "/alldocs" }}
                state={{
                  from: "Иргэний",
                }}
                className="more-btn-text"
              >
              
                  цааш нь
                <FaArrowRight />
            
              </NavLink>
            </div>
          </Tab>
          <Tab eventKey="profile" title="Эрүүгийн хэрэг">
            <CardGroup>
              {latestDataE
                .filter((n) => (n.is_active = 1))
                .map((e) => (
                  <Card>
                    <Card.Img
                      variant="top"
                      src={`https://judilogy.mn/api/doc/image/${e.image_url}`}
                    />
                    <Card.Body>
                      <Card.Title>{e.title}</Card.Title>
                      <Card.Text>{e.description}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">
                        {calculateTimePassed(e.created_at)}
                      </small>
                    </Card.Footer>
                  </Card>
                ))}
            </CardGroup>
            <div className="more-btn">
            <NavLink
                to={{ pathname: "/khereg" }}
                state={{
                  from: "Эрүүгийн",
                }}
              >
              
                  цааш нь
                <FaArrowRight />
            
              </NavLink>
            </div>
          </Tab>
          <Tab eventKey="longer-tab" title="Захиргааны хэрэг">
            <CardGroup>
              {latestDataZ
                .filter((n) => (n.is_active = 1))
                .map((e) => (
                  <Card>
                    <Card.Img
                      variant="top"
                      src={`https://judilogy.mn/api/doc/image/${e.image_url}`}
                    />
                    <Card.Body>
                      <Card.Title>{e.title}</Card.Title>
                      <Card.Text>{e.description}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">
                        {calculateTimePassed(e.created_at)}
                      </small>
                    </Card.Footer>
                  </Card>
                ))}
            </CardGroup>
            <div className="more-btn">
            <NavLink
                to={{ pathname: "/khereg" }}
                state={{
                  from: "Захиргааны",
                }}
              >
              
                  цааш нь
                <FaArrowRight />
            
              </NavLink>
            </div>
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
}

export default Docs;
