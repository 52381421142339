import React, { useEffect, useState } from "react";
import { services } from "../services/services";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

function Bench(props) {
  const [bench, setBench] = useState([]);
  useEffect(() => {
    services
      .getBench()
      .then((res) => res.json())
      .then((res) => setBench(res));
  }, []);
  const [benchId, setBenchId] = useState({
    id: "",
    title: "",
  });

  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/bench/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        setBenchId(...data);
      });
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("phone", phone);
    data.append("barbench_id", benchId.id);

    axios({
      method: "POST",
      url: "https://judilogy.mn/api/bench/register",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShow(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <div id="bench">
      <body>
        <div className="bench-header">
          <h3 className="title-section">Хэлэлцүүлэг</h3>
        </div>
        <div className="row1-container">
          {bench.map((n) => (
            <div
              className={
                (n.is_active =
                  1 && n.color == "Green"
                    ? "box box-down cyan-bar"
                    : (n.is_active =
                        1 && n.color == "Red"
                          ? "box red-bar"
                          : (n.is_active =
                              1 && n.color == "Blue"
                                ? "box box-down blue-bar"
                                : n.color == "Orange"
                                ? "d-none"
                                : "")))
              }
            >
              <h2>{n.title}</h2>
              <p>{n.description}</p>

              <button
                className="bar-btn"
                onClick={() => {
                  handleShow();
                  showDetail(n.id);
                }}
              >
                Энд дарж бүртгүүлнэ үү
              </button>
            </div>
          ))}
        </div>

        <div className="row2-container">
          {bench
            .filter((e) => (e.is_active = 1 && e.color == "Orange"))
            .map((n) => (
              <div className="box orange-bar">
                <h2>{n.title}</h2>
                <p>{n.description}</p>
                <button
                  className="bar-btn"
                  onClick={() => {
                    handleShow();
                    showDetail(n.id);
                  }}
                >
                  Энд дарж бүртгүүлнэ үү
                </button>
              </div>
            ))}
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{benchId.title} хэлэлцүүлэгт бүртгүүлэх</Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body className="bench-form">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Нэр</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Нэрээ оруулна уу"
                  autoFocus
                  value={name}
                  onChange={(e)=>{setName(e.target.value)}}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Имэйл хаяг</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Имэйл хаягаа оруулна уу"
                  autoFocus
                  value={email}
                  onChange={(e)=>{setEmail(e.target.value)}}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Утас</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Утасны дугаараа оруулна уу"
                  autoFocus
                  value={phone}
                  onChange={(e)=>{setPhone(e.target.value)}}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleSubmit} className="bench-reg-btn">
                Бүртгүүлэх
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </body>
    </div>
  );
}

export default Bench;
