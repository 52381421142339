import React, { useRef } from "react";
import { Container } from "react-bootstrap";
import Header from "./Header";

function Text(props) {
  return (
    <div className="text-bg">
      <Header />
      <Container className="text">
        <h2 className="header-text">
          Монгол Улсад хууль ёсоор оршин суугаа хүн
         бүр хууль, шүүхийн өмнө эрх
          тэгш байна.</h2>

        <p className="header-text-small">
          <i>Монгол Улсын Үндсэн хууль Арван дөрөвдүгээр зүйлийн 1 дэх хэсэг</i>
        </p>
      </Container>
    </div>
  
  );
}

export default Text;
