import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa6";
import Moment from "moment";
import { services } from "../services/services";
import { NavLink, useLocation } from "react-router-dom";

function Research(props) {
  
  const [research, setResearch] = useState([]);
  useEffect(() => {
    services
      .getSudalgaa()
      .then((res) => res.json())
      .then((res) => setResearch(res));
  }, []);
  const sortedData = research.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // Select the latest 3 data items
  const latestDataR = sortedData.filter((a) => a.color == "Red").slice(0, 1);
  const latestDataG = sortedData.filter((a) => a.color == "Green").slice(0, 1);
  const latestDataB = sortedData.filter((a) => a.color == "Blue").slice(0, 1);

  return (
    <div>
      <Container id="research-docs">
        <h3 className="title-section mb-4">Судалгааны ажлууд</h3>
        {latestDataB
          .filter((n) => (n.is_active = 1))
          .map((e) => (
            <article className="postcard light blue">
              <a className="postcard__img_link" href="/">
                <img
                  className="postcard__img"
                  src={`https://judilogy.mn/api/research/image/${e.image_url}`}
                  alt="aa"
                />
              </a>
              <NavLink
                className="postcard__text t-dark"
                to={{ pathname: "/rs" }}
                state={{
                  from: e.id,
                  from1: e.title,
                  from2: e.description,
                  from3: e.file
                }}
              >
                <h1 className="postcard__title blue">
                  <a href="/rs">{e.title}</a>
                </h1>
                <div className="postcard__subtitle small">
                  <time datetime="2020-05-25 12:00:00">
                    <i className="fas fa-calendar-alt mr-2"></i>
                    {Moment(e.created_at).format("YYYY-MM-DD")}
                  </time>
                </div>
                <div className="postcard__bar"></div>
                <div className="postcard__preview-txt">{e.description}</div>
              </NavLink>
            </article>
          ))}
        {latestDataR
          .filter((n) => (n.is_active = 1))
          .map((e) => (
            <article className="postcard light red">
              <a className="postcard__img_link" href="/">
                <img
                  className="postcard__img"
                  src={`https://judilogy.mn/api/research/image/${e.image_url}`}
                  alt="ImageTitle"
                />
              </a>
              <NavLink
                className="postcard__text t-dark"
                to={{ pathname: "/rs" }}
                state={{
                  from: e.id,
                  from1: e.title,
                  from2: e.description,
                  from3: e.file
                }}
              >
                <h1 className="postcard__title red">
                  <a href="/">{e.title} </a>
                </h1>
                <div className="postcard__subtitle small">
                  <time datetime="2020-05-25 12:00:00">
                    <i className="fas fa-calendar-alt mr-2"></i>
                    {Moment(e.created_at).format("YYYY-MM-DD")}
                  </time>
                </div>
                <div className="postcard__bar"></div>
                <div className="postcard__preview-txt">{e.description}</div>
              </NavLink>
            </article>
          ))}
        {latestDataG
          .filter((n) => (n.is_active = 1))
          .map((e) => (
            <article className="postcard light green">
              <a className="postcard__img_link" href="/">
                <img
                  className="postcard__img"
                  src={`https://judilogy.mn/api/research/image/${e.image_url}`}
                  alt="ImageTitle"
                />
              </a>

              <NavLink
                className="postcard__text t-dark"
                to={{ pathname: "/rs" }}
                state={{
                  from: e.id,
                  from1: e.title,
                  from2: e.description,
                  from3: e.file
                }}
              >
                <h1 className="postcard__title green">
                  <a href="/">{e.title} </a>
                </h1>
                <div className="postcard__subtitle small">
                  <time datetime="2020-05-25 12:00:00">
                    <i className="fas fa-calendar-alt mr-2"></i>
                    {Moment(e.created_at).format("YYYY-MM-DD")}
                  </time>
                </div>
                <div className="postcard__bar"></div>
                <div className="postcard__preview-txt">{e.description}</div>
              </NavLink>
            </article>
          ))}
        <div className="more-btn">
          <NavLink
            to={{ pathname: "/library" }}
            state={{
              from: "",
            }}
          >
            цааш нь <FaArrowRight />
          </NavLink>
        </div>
      </Container>
    </div>
  );
}

export default Research;
