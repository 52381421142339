import './App.css';
import Home from './components/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ResearchDetail from './components/ResearchDetail';
import Khereg from './components/Khereg';
import Admin from './components/Admin';
import Login from './components/Login';
import Podcast from './components/Podcast';
import NotFound from './components/NotFound';
import Library from './components/Library';
import WeekDocs from './components/WeekDocs';
import AllDocs from './components/AllDocs';
import News from './components/News';
import NewsDetail from './components/NewsDetail';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/rs" element={<ResearchDetail/>}></Route>
      <Route path="/ns" element={<NewsDetail/>}></Route>
      <Route path="/khereg" element={<Khereg/>}></Route>
      <Route path="/admin" element={<Admin/>}></Route>
      <Route path="/login" element={<Login/>}></Route>
      <Route path="/podcasts" element={<Podcast/>}></Route>
      <Route path="/library" element={<Library />}></Route>
      <Route path="/weeknews" element={<WeekDocs />}></Route>
      <Route path='/alldocs' element={<AllDocs />}></Route>
      <Route path='/news' element={<News />}></Route>
      <Route path="/*" element={<NotFound/>}></Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
