import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Card, CardGroup, Container, Modal, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { services } from "../services/services";
import Footer from "./Footer";
import { Document, Page, pdfjs } from "react-pdf";
import axios from "axios";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
function WeekDocs(props) {
  const location = useLocation();
  const path = location.pathname;
  const [docs, setDocs] = useState([]);
  useEffect(() => {
    services
      .getDocs()
      .then((res) => res.json())
      .then((res) => setDocs(res));
  }, []);
  const sortedData = docs.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // Select the latest 3 data items
  const latestData = sortedData.slice(0, 3);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [docId, setDocId] = useState({
    id: "",
    title: "",
    filepdf: "",
  });

  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/doc/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        setDocId(...data);
      });
  };

  useEffect(() => {
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    window.addEventListener("contextmenu", disableRightClick);

    return () => {
      window.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);
  useEffect(() => {
    const handleBeforeCapture = (e) => {
      e.preventDefault();
      alert("Taking screenshots is not allowed.");
    };

    window.addEventListener("beforeprint", handleBeforeCapture);

    return () => {
      window.removeEventListener("beforeprint", handleBeforeCapture);
    };
  }, []);

  return (
    <div>
      <div className="header-bg">
        {" "}
        <Header />
      </div>

      <Container>
        <ul className="breadcrumb">
          <a href="/" className="breadcrumb__item breadcrumb__item-firstChild">
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">Нүүр</span>
              </span>
            </li>
          </a>

          <li
            className={
              path === "/weeknews" ? "selected_title" : "breadcrumb__item"
            }
          >
            <span className="breadcrumb__inner">
              <span className="breadcrumb__title">
                Энэ 7 хоногийн ХМ хуудас
              </span>
            </span>
          </li>
        </ul>

        <CardGroup className="week-doc">
          {latestData
            .filter((n) => (n.is_active = 1))
            .map((e) => (
              <Card className="doc-read">
                <Card.Img
                  variant="top"
                  src={`https://judilogy.mn/api/doc/image/${e.image_url}`}
                />
                <Card.Body>
                  <Card.Title>{e.title}</Card.Title>
                  <Card.Text>{e.docs_type} хэрэг</Card.Text>
                </Card.Body>
                <Card.Footer
                  onClick={() => {
                    setShow(true);
                    showDetail(e.id);
                  }}
                >
                  Унших
                </Card.Footer>
              </Card>
            ))}
        </CardGroup>
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <Navbar.Brand href="/">
                <img src="/judilogy_logo.png" alt="" className="logo" />
              </Navbar.Brand>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pdf-view">
            <div className="pdf-div">
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <Document
                file={`https://judilogy.mn/api/files/docs/${docId.filepdf}`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page) => {
                    return (
                      <Page
                        pageNumber={page}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                      />
                    );
                  })}
              </Document>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
      <Footer />
    </div>
  );
}

export default WeekDocs;
