import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {
  FaFacebook,
  FaInstagram,
  FaXTwitter,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa6";
import { NavLink } from "react-router-dom";




function Header() {
  const [lgShow, setLgShow] = useState(false);
  const [role, setRole] = useState("");
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setRole(user);
    }
  }, []);
  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <img src="/logo_transparent.png" alt="" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav " />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/#aboutnew">Бидний тухай</Nav.Link>
              <Nav.Link href="/#docs-slide">Хэргийн мэдээллийн хуудас</Nav.Link>
              <Nav.Link href="/#bench">Bar&Bench Dialogue</Nav.Link>
              <Nav.Link>Судалгааны сан</Nav.Link>
              <Nav.Link onClick={() => setLgShow(true)}>
                Хамтын ажиллагаа
              </Nav.Link>
              <Nav.Link>
                <NavLink  to ={role ? {pathname: "/admin"} : {pathname: "/login"} } className= "judilogy-login">Judilogy Login</NavLink>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
      >
        <Modal.Header className="md-header">
          <Modal.Title id="example-modal-sizes-title-lg">
            Хамтын ажиллагаа
          </Modal.Title>
          <div className="outer" onClick={() => setLgShow(false)}>
            <div className="inner">
              <label>Баярлалаа</label>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="md-text">
            <p>
              Бидэнтэй хамтран ажиллахыг хүсвэл дараах имэйлээр холбогдоно уу
            </p>
            <a href="mailto: judilogy@gmail.com">
              {" "}
              <h5 className="md-email">JUDILOGY@GMAIL.COM</h5>
            </a>
            <Row>
              <Col>
                <a href="https://www.facebook.com/judilogy" target="_blank">
                  {" "}
                  <FaFacebook className="aa" />
                </a>
                <a href="https://www.youtube.com/@judilogy" target="_blank">
                  {" "}
                  <FaYoutube className="ft-icon aa" />
                </a>
                <a href="https://twitter.com/judilogy" target="_blank">
                  <FaXTwitter className="ft-icon aa" />
                </a>
                <a href="https://www.instagram.com/judilogy/" target="_blank">
                  <FaInstagram className="ft-icon aa" />
                </a>
                <a href="https://www.linkedin.com/company/97418768/admin/feed/posts/" target="_blank">
                  {" "}
                  <FaLinkedin className="ft-icon aa" />
                </a>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
