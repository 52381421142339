import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Container } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";

function NewsDetail(props) {
  const location = useLocation();
  const { state } = location;
  const data = state.from;
  const data1 = state.from1;
  const data2 = state.from2;
  const path = location.pathname;
  return (
    <div>
      <div className="header-bg">  <Header /></div>
    
      <Container>
        <ul className="breadcrumb">
          <a href="/" className="breadcrumb__item breadcrumb__item-firstChild">
            {" "}
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">Нүүр</span>
              </span>
            </li>
          </a>

          <NavLink
            to={{ pathname: "/news" }}
            state={{
              from: data1,
            }}
            className="breadcrumb__item"
          >
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">Мэдээний товхимол</span>
              </span>
            </li>
          </NavLink>
          <NavLink
            to={{ pathname: "/ns" }}
            state={{
              from: data1,
            }}
            className={path == "/ns" ? "selected_title" : "breadcrumb__item"}
          >
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">{data1}</span>
              </span>
            </li>
          </NavLink>
        </ul>
        <h3 className="rs-title">{data1}</h3>

        <div className="rs-body-text">
          <p className="rs-body-text">{data2}</p>

          <p>
            Эх сурвалж: <a href="/">Lorem, ipsum dolor.</a>
          </p>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default NewsDetail;
