import React, { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Modal,
  Navbar,
} from "react-bootstrap";
import { services } from "../services/services";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
function Khereg(props) {
  const location = useLocation();
  const path = location.pathname;
  const { state } = location;
  const data_type = state.from;

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [docs, setDocs] = useState([]);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [docId, setDocId] = useState({
    id: "",
    title: "",
    filepdf: "",
  });

  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/doc/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        setDocId(...data);
      });
  };

  useEffect(() => {
    services
      .getDocs()
      .then((res) => res.json())
      .then((res) => setDocs(res));
  }, []);

  useEffect(() => {
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    window.addEventListener("contextmenu", disableRightClick);

    return () => {
      window.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);
  useEffect(() => {
    const handleBeforeCapture = (e) => {
      e.preventDefault();
      alert("Taking screenshots is not allowed.");
    };

    window.addEventListener("beforeprint", handleBeforeCapture);

    return () => {
      window.removeEventListener("beforeprint", handleBeforeCapture);
    };
  }, []);
  return (
    <div>
      <div className="header-bg">
        {" "}
        <Header />
      </div>

      <Container>
        <ul className="breadcrumb">
          <a href="/" className="breadcrumb__item breadcrumb__item-firstChild">
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">Нүүр</span>
              </span>
            </li>
          </a>
          <NavLink
            to={{ pathname: "/alldocs" }}
            state={{
              from: data_type,
            }}
            className="breadcrumb__item"
          >
            {/* <a href="/alldocs" className="breadcrumb__item"> */}
            <li className="">
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">
                  Хэргийн мэдээллийн хуудас
                </span>
              </span>
            </li>
          </NavLink>

          <li
            className={
              path == "/khereg" ? "selected_title" : "breadcrumb__item"
            }
          >
            <span className="breadcrumb__inner">
              <span className="breadcrumb__title">
                {data_type == "Иргэний"
                  ? "Иргэний хэрэг"
                  : data_type == "Эрүүгийн"
                  ? "Эрүүгийн хэрэг"
                  : "Захиргааний хэрэг"}
              </span>
            </span>
          </li>
        </ul>
        {/* <h3 className="rs-title">Нээлттэй нийгэм форум</h3> */}
        <Row md={4}>
          {docs
            .filter((n) => (n.is_active = 1 && n.docs_type == data_type))
            .map((e) => (
              <Col>
                {" "}
                <Card className="docs-card">
                  <Card.Img
                    variant="top"
                    src={`https://judilogy.mn/api/doc/image/${e.image_url}`}
                  />
                  <Card.Body>
                    <Card.Title>{e.title}</Card.Title>

                    <Button
                      variant="primary"
                      onClick={() => {
                        setShow(true);
                        showDetail(e.id);
                      }}
                      className="docs-btn"
                    >
                      Унших
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <Navbar.Brand href="/">
                <img src="/judilogy_logo.png" alt="" className="logo" />
              </Navbar.Brand>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pdf-view">
            <div className="pdf-div">
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <Document
                file={`https://judilogy.mn/api/files/docs/${docId.filepdf}`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page) => {
                    return (
                      <Page
                        pageNumber={page}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                      />
                    );
                  })}
              </Document>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
      <Footer />
    </div>
  );
}

export default Khereg;
