import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Footer from "./Footer";
import { NavLink, useLocation } from "react-router-dom";
import { services } from "../services/services";

function News(props) {
  const location = useLocation();
  const path = location.pathname;
  const { state } = location;
  const data = state.from;

  const [news, setNews] = useState([]);
  useEffect(() => {
    services
      .getNews()
      .then((res) => res.json())
      .then((res) => setNews(res));
  }, []);
  return (
    <div>
      <div className="header-bg">
        {" "}
        <Header />
      </div>

      <Container>
        <ul className="breadcrumb">
          <a href="/" className="breadcrumb__item breadcrumb__item-firstChild">
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">Нүүр</span>
              </span>
            </li>
          </a>
          <NavLink
            // to={{ pathname: "/alldocs" }}
            // state={{
            //   from: data_type,
            // }}
            className={path == "/news" ? "selected_title" : "breadcrumb__item"}
          >
            {/* <a href="/alldocs" className="breadcrumb__item"> */}
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">Мэдээний товхимол</span>
              </span>
            </li>
          </NavLink>
          <NavLink
            to={{ pathname: "/ns" }}
            state={{
              from: data,
              from1: data,
            }}
            className={data == "" ? "d-none" : "breadcrumb__item"}
          >
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">{data}</span>
              </span>
            </li>
          </NavLink>
        </ul>

        <Row md={4}>
          {news
            .filter((n) => (n.is_active = 1))
            .map((e) => (
              <Col>
                {" "}
                <Card className="docs-card">
                  <Card.Img
                    variant="top"
                    src={`https://judilogy.mn/api/news/image/${e.image_url}`}
                  />
                  <Card.Body>
                    <Card.Title>{e.title}</Card.Title>
                    <NavLink
                      to={{ pathname: "/ns" }}
                      state={{
                        from: e.id,
                        from1: e.title,
                        from2: e.description,
                      }}
                    >
                      {" "}
                      <Button variant="primary" className="docs-btn">
                        Унших
                      </Button>
                    </NavLink>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default News;
