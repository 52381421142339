import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './styles/header.css';
import './styles/wedo.css';
import './styles/week.css';
import './styles/register.css';
import './styles/footer.css';
import './styles/bench.css';
import './styles/docs.css';
import './styles/research.scss';
import './styles/rsdetail.css';
import './styles/home.css';
import './styles/admin.css';
import './styles/podcast.css';
import './styles/pcast.scss';
import './styles/notfound.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
