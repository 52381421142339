import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Container, Card } from "react-bootstrap";
import Slider from "react-slick";
import { services } from "../services/services";

function Podcast(props) {
  const settings = {
    className: "center",
    dots: true,
    centerMode: true,
    infinite: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 500,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [podcast, setPodcast] = useState([]);
  const [latestData, setLatestData] = useState(null);
  useEffect(() => {
    services
      .getPodcast()
      .then((res) => res.json())
      .then((res) => {
        setPodcast(res);
        const maxTimestamp = res.reduce((max, item) => {
          return new Date(item.created_at) > new Date(max.created_at)
            ? item
            : max;
        }, res[0]);

        // Set the latest data
        setLatestData(maxTimestamp);
      });
  }, []);

  return (
    <div>
      <Header />
      <Container>
      
        <h3 class="text-center podcast-title">
          <b>Энэ 7 хоногийн подкаст</b>
        </h3>
        <div className="d-flex justify-content-center pdcast">
          {latestData && (
            <div
              className="card-podcast-last"
              dangerouslySetInnerHTML={{ __html: latestData.link }}
            ></div>
          )}
        </div>

        <h4 class="text-center podcast-title">
          <b>Манай подкастууд</b>
        </h4>
        <Slider {...settings} className="podcast-slider">
          {podcast.filter((n) => (n.is_active = 1)).map((e) => (
            <Card className="wedo-card">
              <div
                className="card-podcast"
                dangerouslySetInnerHTML={{ __html: e.link }}
              ></div>

              <Card.Body className="card-body-podcast">
                <Card.Title className="wedo-title">{e.title}</Card.Title>
                <Card.Text className="wedo-text">{e.description}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Slider>
      </Container>
      <Footer />
    </div>
  );
}

export default Podcast;
