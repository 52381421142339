import React from 'react';
import { Col, Row } from 'react-bootstrap';

function NotFound(props) {
    return (
        <div className='notfound'>
            <Row>
                <Col>
                <img src="/judilogy_logo.png" alt="" />
                </Col>
                <Col className='notfound-back'>
                <p>Хуудас олдсонгүй!</p>
                <a href="/"><p className='back-text'>Буцах</p></a>
                </Col>
            </Row>
            
        </div>
    );
}

export default NotFound;