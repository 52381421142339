import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";

function AboutNew(props) {
  return (
    <div className="container px-2 px-md-4 py-5 mx-auto mt-5" id="aboutnew">
      <h3 className="title-section">Бидний тухай</h3>

      <div className="card-profile d-flex justify-content-center">
        <div className="card profile py-2 px-2">
          <Row>
            <Col>
              <a href="https://www.facebook.com/judilogy" target="_blank">
                {" "}
                <FaFacebook className="aa" />
              </a>
              <a href="https://www.youtube.com/@judilogy" target="_blank">
                {" "}
                <FaYoutube className="ft-icon aa" />
              </a>
              <a href="https://twitter.com/judilogy" target="_blank">
                <FaXTwitter className="ft-icon aa" />
              </a>
              <a href="https://www.instagram.com/judilogy/" target="_blank">
                <FaInstagram className="ft-icon aa" />
              </a>
              <a href="https://www.linkedin.com/company/97418768/admin/feed/posts/" target="_blank">
                {" "}
                <FaLinkedin className="ft-icon aa" />
              </a>
            </Col>
          </Row>
        </div>
      </div>

      <div className="desc-section mt-4 p-4">
        <p className="text-start">
          Judilogy нь 2023 онд үүсгэн байгуулагдсан дотоодын нийгэмд үйлчлэх
          төрийн бус байгууллага (Тогтолцоо дүн шинжилгээ судалгааны хүрээлэн
          НҮТББ) юм.{" "}
        </p>
        <p className="text-start">
          Бидний үйл ажиллагааны гол зорилго нь Монгол Улсын шүүхийн шийдвэрийг
          судалгааны эргэлтэд оруулах, олон нийтэд таниулах замаар эрх зүйт ёсыг
          төлөвшүүлэхэд хувь нэмэрээ оруулах явдал юм. Энэхүү зорилгын хүрээнд
          эрх зүйн болон бодлогын судалгаа гүйцэтгэх, хэлэлцүүлэг өрнүүлэх,
          хууль зүйн мэдээ бэлтгэх зэрэг хууль, эрх зүйн мэдээ мэдээллийг олон
          нийтэд ойлгомжтой байдлаар хүргэн ажиллаж байна.
        </p>
      </div>
    </div>
  );
}

export default AboutNew;
