import React, { useEffect, useState } from "react";
import { Container, Form, InputGroup } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";
import { services } from "../services/services";
import Moment from "moment";
import { useLocation, NavLink } from "react-router-dom";
import { IoSearch } from "react-icons/io5";

function Library(props) {
  const [searchDocs, setSearchDocs] = useState("");
  const location = useLocation();
  const path = location.pathname;
  const { state } = location;
  const data = state.from;
  const [research, setResearch] = useState([]);
  useEffect(() => {
    services
      .getSudalgaa()
      .then((res) => res.json())
      .then((res) => setResearch(res));
  }, []);
  return (
    <div>
      <div className="header-bg">
        <Header />
      </div>

      <Container>
        <ul className="breadcrumb">
          <a href="/" className="breadcrumb__item breadcrumb__item-firstChild">
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">Нүүр</span>
              </span>
            </li>
          </a>

          <li
            className={
              path == "/library" ? "selected_title" : "breadcrumb__item"
            }
          >
            <span className="breadcrumb__inner">
              <span className="breadcrumb__title">Судалгааны сан</span>
            </span>
          </li>
          <NavLink
            to={{ pathname: "/rs" }}
            state={{
              from: data,
              from1: data,
            }}
            className={data == "" ? "d-none" : "breadcrumb__item"}
          >
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">{data}</span>
              </span>
            </li>
          </NavLink>
        </ul>
        <InputGroup>
          <Form.Control
            className="search"
            placeholder="Search"
            aria-label="Username"
            aria-describedby="basic-addon1"
            type="search"
            onChange={(e) => {
              setSearchDocs(e.target.value);
            }}
          />
          <InputGroup.Text id="basic-addon1">
            <IoSearch />
          </InputGroup.Text>
        </InputGroup>
        {research
          .filter((val) => {
            if (searchDocs === "") {
              return val;
            } else if (
              val.title.toLowerCase().includes(searchDocs.toLowerCase()) ||
              val.description.toLowerCase().includes(searchDocs.toLowerCase())
            ) {
              return val;
            }
          })
          .map((e) => (
            <article
              className={
                e.color == "Blue"
                  ? "postcard light blue"
                  : e.color == "Red"
                  ? "postcard light red"
                  : "postcard light green"
              }
            >
              <div className="postcard__img_link">
                <img
                  className="postcard__img"
                  src={`https://judilogy.mn/api/research/image/${e.image_url}`}
                  alt="aa"
                />
              </div>
              <NavLink
                className="postcard__text t-dark"
                to={{ pathname: "/rs" }}
                state={{
                  from: e.id,
                  from1: e.title,
                  from2: e.description,
                  from3: e.file,
                }}
              >
                <h1
                  className={
                    e.color == "Blue"
                      ? "postcard__title blue"
                      : e.color == "Red"
                      ? "postcard__title red"
                      : "postcard__title green"
                  }
                >
                  {e.title}
                </h1>
                <div className="postcard__subtitle small">
                  <time datetime="2020-05-25 12:00:00">
                    <i className="fas fa-calendar-alt mr-2"></i>{" "}
                    {Moment(e.created_at).format("YYYY-MM-DD")}
                  </time>
                </div>
                <div className="postcard__bar"></div>
                <div className="postcard__preview-txt">{e.description}</div>
              </NavLink>
            </article>
          ))}
      </Container>
      <Footer />
    </div>
  );
}

export default Library;
