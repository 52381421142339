import React, { useState } from "react";
import { Col, Container, Modal, Nav, Row } from "react-bootstrap";
import {
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaXTwitter,
  FaCopyright,
  FaLinkedin,
} from "react-icons/fa6";

function Footer(props) {
  const [serviceShow, setServiceShow] = useState(false);
  return (
    <div className="footer">
      <hr />
      <Container>
        <Row >
          <Col sm={12} xl={2} className="text-center ftr">
            <Nav>
              <Nav.Link className="footer-link" href="/#aboutnew">
                Танилцуулга
              </Nav.Link>
            </Nav>
          </Col>
          <Col sm={12} xl={2} className="text-center ftr">
            <Nav>
              <Nav.Link className="footer-link" onClick={() => setServiceShow(true)}>
                Үйлчилгээний нөхцөл
              </Nav.Link>
            </Nav>
          </Col>

          <Col className="pt-2 text-center ft-reg ftr" sm={12}  xl={2}>
            <a
              href="https://www.facebook.com/judilogy"
              className="footer-icon"
              target="_blank"
            >
              <FaFacebook />
            </a>
            <a href="https://www.youtube.com/@judilogy" target="_blank">
              <FaYoutube className="footer-icon ft-icon" />
            </a>
            <a href="https://twitter.com/judilogy" target="_blank">
              <FaXTwitter className="footer-icon ft-icon" />
            </a>
            <a href="https://www.instagram.com/judilogy/" target="_blank">
              {" "}
              <FaInstagram className="footer-icon ft-icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/97418768/admin/feed/posts/"
              target="_blank"
            >
              {" "}
              <FaLinkedin className="footer-icon ft-icon" />
            </a>
          </Col>

          <Col  className="pt-2 text-center ft-reg ftr" sm={12}  xl={2}>
            Email:{" "}
            <a className="footer-icon" href="email to: judilogy@gmail.com">
              judilogy@gmail.com
            </a>
          </Col>
          <Col  className="pt-2 text-end  ft-reg ftr" sm={12} xl={2}>
            <FaCopyright />
            <span className="m-1"> 2024. Judilogy</span>
           
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={serviceShow}
        onHide={() => setServiceShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Small Modal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="service-con">...</Modal.Body>
      </Modal>
     
    </div>
  );
}

export default Footer;
