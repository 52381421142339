import React from "react";
import { Card, CardGroup, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function Week(props) {
  return (
    <div className="week mt-4">
      <Container>
        <h3 className="title-section">Энэ 7 хоногт</h3>
     
        <CardGroup className="my-4">
      
          <Card >
            <Card.Img variant="top" src="/newss.png" />
            <NavLink
              to={{ pathname: "/news" }}
              state={{
                from: "",
              }}
            >
              <Card.Body>
                <Card.Title className="week-title">Мэдээллийн товхимол</Card.Title>
              </Card.Body>
            </NavLink>
            <hr className="card-top"/>
          </Card>
         
          <Card>
            <Card.Img variant="top" src="/searchdoc.png" />
            <NavLink to={{ pathname: "/weeknews" }}>
              <Card.Body>
                <Card.Title className="week-title">
                  Энэ 7 хоногийн хэргийн мэдээллийн хуудас
                </Card.Title>
              </Card.Body>
            </NavLink>
            <hr className="card-top"/>
          </Card>
          <Card>
            <Card.Img variant="top" src="/Podcast.png" />
            <NavLink to={{ pathname: "/podcasts" }}>
              <Card.Body >
                <Card.Title className="week-title">Энэ 7 хоногийн подкаст</Card.Title>
              </Card.Body>
            </NavLink>
            <hr className="card-top"/>
          </Card>
        </CardGroup>
      </Container>
    </div>
  );
}

export default Week;
