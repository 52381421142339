import React, { useEffect, useState } from "react";
import Header from "./Header";
import Text from "./Text";
import Wedo from "./Wedo";
import Week from "./Week";
import Research from "./Research";
import Register from "./Register";
import Footer from "./Footer";
import About from "./About";
import Docs from "./Docs";
import Bench from "./Bench";
import AboutNew from "./AboutNew";

function Home(props) {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    window.onscroll = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  }, []);
  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  // useEffect(() => {
  //   const disableRightClick = (e) => {
  //     e.preventDefault();
  //   };

  //   window.addEventListener("contextmenu", disableRightClick);

  //   return () => {
  //     window.removeEventListener("contextmenu", disableRightClick);
  //   };
  // }, []);
  // useEffect(() => {
  //   const handleBeforeCapture = (e) => {
  //     e.preventDefault();
  //     alert('Taking screenshots is not allowed.');
  //   };

  //   window.addEventListener('beforeprint', handleBeforeCapture);

  //   return () => {
  //     window.removeEventListener('beforeprint', handleBeforeCapture);
  //   };
  // }, []);

  return (
    <div>
      {/* <Header /> */}
     
      <Text />
      <AboutNew />
      <Wedo />
      <button
        id="myBtn"
        title="Go to top"
        onClick={scrollToTop}
        style={{ display: isVisible ? 'block' : 'none' }}
      >
        Дээшээ
      </button>
      <Week />
      <Research />
      <Register />
      
      <Docs />
      <Bench />
      <Footer />
    </div>
  );
}

export default Home;
