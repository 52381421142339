import authHeaders from "./auth-header";

const base_url = "https://judilogy.mn";
const getWedo = async () => {
  return await fetch(`${base_url}/api/wwd`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getRegister= async () => {
  return await fetch(`${base_url}/api/guest`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": authHeaders(),
    },
  });
};
const getNews= async () => {
  return await fetch(`${base_url}/api/news`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getPodcast= async () => {
  return await fetch(`${base_url}/api/podcast`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getBench= async () => {
  return await fetch(`${base_url}/api/bench`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getBenchReg= async () => {
  return await fetch(`${base_url}/api/barbench`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": authHeaders(),
    },
  });
};
const getDocs= async () => {
  return await fetch(`${base_url}/api/doc`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getSudalgaa= async () => {
  return await fetch(`${base_url}/api/research`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const services = { getWedo, getRegister, getBenchReg, getNews, getPodcast, getBench, getDocs, getSudalgaa };
