import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import { MdDelete, MdEdit, MdCancel, MdImage } from "react-icons/md";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { FaRegUserCircle } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { services } from "../services/services";
import Moment from "moment";
import { IoMdExit } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import authHeaders from "../services/auth-header";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { userService } from "../services/userService";

function Admin(props) {
  const [admin, setAdmin] = useState([]);

  useEffect(() => {
    userService
      .getAllUser()
      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          navigate({
            pathname: "/login",
          });
        } else return response.json();
      })
      .then((res) => setAdmin(res));
  }, []);
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setRole(user);
    }
  }, []);
  const [exit, setExit] = useState(false);
  const clearLocalStorage = () => {
    localStorage.clear();
    setExit(!exit);
    navigate({
      pathname: "/login",
    });
  };

  const checkExit = () => {
    setExit(!exit);
  };
  const [selectedItem, setSelectedItem] = useState("News");

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const renderForm = () => {
    switch (selectedItem) {
      case "News":
        return <NewsForm role={role.username} />;
      case "Wedo":
        return <WedoForm role={role.username} />;
      case "Sudalgaa":
        return <SudalgaaForm role={role.username} />;
      case "Register":
        return <RegisterForm role={role.username} />;
      case "Barbench":
        return <BarbenchForm role={role.username} />;
      case "User":
        return <UserForm role={role.username} />;
      case "Material":
        return <MaterialForm role={role.username} />;
      case "Podcast":
        return <PodcastForm role={role.username} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="header-bg">
        <Header />
      </div>

      <div>
        <ul className="admin-body">
          <li
            className={`admin-menu ${
              selectedItem === "News" ? "admin-selected-menu" : ""
            }`}
            onClick={() => handleItemClick("News")}
          >
            News
          </li>
          <li
            className={`admin-menu ${
              selectedItem === "Wedo" ? "admin-selected-menu" : ""
            }`}
            onClick={() => handleItemClick("Wedo")}
          >
            We do
          </li>
          <li
            className={`admin-menu ${
              selectedItem === "Sudalgaa" ? "admin-selected-menu" : ""
            }`}
            onClick={() => handleItemClick("Sudalgaa")}
          >
            Sudalgaa
          </li>
          <li
            className={`admin-menu ${
              selectedItem === "Material" ? "admin-selected-menu" : ""
            }`}
            onClick={() => handleItemClick("Material")}
          >
            Material
          </li>
          <li
            className={`admin-menu ${
              selectedItem === "Register" ? "admin-selected-menu" : ""
            }`}
            onClick={() => handleItemClick("Register")}
          >
            Register
          </li>
          <li
            className={`admin-menu ${
              selectedItem === "Barbench" ? "admin-selected-menu" : ""
            }`}
            onClick={() => handleItemClick("Barbench")}
          >
            Barbench
          </li>
          <li
            className={`admin-menu ${
              selectedItem === "Podcast" ? "admin-selected-menu" : ""
            }`}
            onClick={() => handleItemClick("Podcast")}
          >
            Podcast
          </li>
          <li
            className={`admin-menu ${
              selectedItem === "User" ? "admin-selected-menu" : ""
            }`}
            onClick={() => handleItemClick("User")}
          >
            User
          </li>
        </ul>
        <div className="role">
          {" "}
          Сайна байна уу {role.username}{" "}
          <span className="role-exit" onClick={checkExit}>
            <IoMdExit />
          </span>
        </div>
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
          className={
            exit ? "exit_reminder_container" : "exit_reminder_container_dis"
          }
        >
          <div className="exit_reminder">
            <div className="exit_reminder_icon">
              <div className="exit-icon-con">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125ZM9 16.2812C5.09766 16.2812 1.96875 13.1523 1.96875 9.25C1.96875 5.38281 5.09766 2.21875 9 2.21875C12.8672 2.21875 16.0312 5.38281 16.0312 9.25C16.0312 13.1523 12.8672 16.2812 9 16.2812ZM12.7617 7.31641C12.7617 5.55859 10.8984 4.1875 9.17578 4.1875C7.52344 4.1875 6.46875 4.89062 5.66016 6.12109C5.51953 6.29688 5.55469 6.54297 5.73047 6.68359L6.71484 7.42188C6.89062 7.5625 7.17188 7.52734 7.3125 7.35156C7.83984 6.68359 8.22656 6.29688 9.03516 6.29688C9.66797 6.29688 10.4414 6.68359 10.4414 7.31641C10.4414 7.77344 10.0547 7.98438 9.42188 8.33594C8.71875 8.75781 7.76953 9.25 7.76953 10.5156V10.7969C7.76953 11.043 7.94531 11.2188 8.19141 11.2188H9.77344C10.0195 11.2188 10.1953 11.043 10.1953 10.7969V10.5859C10.1953 9.70703 12.7617 9.67188 12.7617 7.31641ZM10.4766 13.1875C10.4766 12.3789 9.80859 11.7109 9 11.7109C8.15625 11.7109 7.52344 12.3789 7.52344 13.1875C7.52344 14.0312 8.15625 14.6641 9 14.6641C9.80859 14.6641 10.4766 14.0312 10.4766 13.1875Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>

            <div className="exit-text">
              <p>Та системээс гарахдаа итгэлтэй </p>
              <p> байна уу ?</p>
            </div>
            <div className="exit_button_container">
              <button onClick={clearLocalStorage}>Тийм</button>
              <button onClick={checkExit}>Үгүй</button>
            </div>
          </div>
        </div>
        {renderForm()}
      </div>
    </>
  );
}

function NewsForm(role) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const [smShow, setSmShow] = useState(false);
  const [newsId, setNewsId] = useState({
    id: "",
    title: "",
    description: "",
    publish: "",
  });

  const user = role.role;

  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/news/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        setNewsId(...data);
      });
  };
  const handleDelete = (e) => {
    e.preventDefault();

    const idToDelete = e.target.alt;
    axios
      .delete(`https://judilogy.mn/api/news/${idToDelete}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": authHeaders(),
        },
      })
      .then((res) => {
        setSmShow(false);
      });
  };

  const [news, setNews] = useState([]);
  useEffect(() => {
    services
      .getNews()
      .then((res) => res.json())
      .then((res) => setNews(res));
  }, [news]);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [image, setImage] = useState("");
  const [draft, setDraft] = useState("Нийтлэх");
  const [is_active, setIs_active] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    data.append("description", desc);
    data.append("file", image);
    data.append("publish", draft);
    data.append("is_active", is_active);

    axios({
      method: "POST",
      url: "https://judilogy.mn/api/news",
      data: data,
      headers: {
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShow(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleTitleChange = (event) => {
    setNewsId({ ...newsId, title: event.target.value });
  };
  const handleDescChange = (event) => {
    setNewsId({ ...newsId, description: event.target.value });
  };
  const handleActiveChange = (event) => {
    setNewsId({ ...newsId, is_active: event.target.value });
  };
  const handleDraftChange = (event) => {
    setNewsId({ ...newsId, publish: event.target.value });
  };
  const handleEdit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", newsId.title);
    data.append("description", newsId.description);
    data.append("is_active", newsId.is_active);
    data.append("publish", newsId.publish);

    axios({
      method: "PUT",
      url: `https://judilogy.mn/api/news/${newsId.id}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShowEdit(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <div className="p-1 m-4">
      <div className="text-end">
        <button className="p-2 m-2 new-btn" onClick={handleShow}>
          Шинэ
        </button>
      </div>
      <Table className="admin-table">
        <thead>
          <tr>
            <th>Гарчиг</th>
            <th>Дэлгэрэнгүй</th>
            <th>Үүсгэсэн огноо</th>
            <th>Засварласан огноо</th>
            <th>Хэрэглэгч</th>
            <th>Идэвхтэй эсэх</th>
            <th>Нийтлэх эсэх</th>
            <th>Зураг</th>
            <th>Удирдах</th>
          </tr>
        </thead>
        <tbody>
          {news.map((e, index) => (
            <tr key={index}>
              <td>{e.title}</td>
              <td>{e.description}</td>
              <td>{Moment(e.created_at).format("YYYY-MM-DD")}</td>
              <td>{Moment(e.updated_at).format("YYYY-MM-DD")}</td>
              <td>{e.created_by}</td>
              <td>{e.is_active == 1 ? "Тийм" : "Үгүй"}</td>
              <td>{e.publish}</td>
              <td className="wwd-img">
                <img
                  src={`https://judilogy.mn/api/news/image/${e.image_url}`}
                />
              </td>
              <td>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setSmShow(true);
                    showDetail(e.id);
                  }}
                >
                  <MdDelete className="table-icon" />
                </button>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setShowEdit(true);
                    showDetail(e.id);
                  }}
                >
                  <MdEdit className="table-icon" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Мэдээ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} enctype="multipart/form-data">
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            >
              <Form.Label className="label-new">Гарчиг</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            >
              <Form.Label className="label-new">Дэлгэрэнгүй</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                style={{ height: "100px" }}
              />
            </Form.Group>

            <input
              type="file"
              name="file"
              onChange={(e) => setImage(e.target.files[0])}
              accept="image/png, image/jpeg"
            />

            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              value={draft}
              onChange={(e) => setDraft(e.target.value)}
            >
              <Form.Label className="label-new">Нийтлэх эсэх</Form.Label>
              <Form.Select aria-label="Default select example " required>
                <option value="Нийтлэх">Нийтлэх</option>
                <option value="Ноороглох">Ноороглох</option>
              </Form.Select>
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Засах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form enctype="multipart/form-data">
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Гарчиг</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={newsId.title}
                onChange={handleTitleChange}
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Дэлгэрэнгүй</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                value={newsId.description}
                onChange={handleDescChange}
                style={{ height: "100px" }}
              />
            </Form.Group>

            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              onChange={handleActiveChange}
            >
              <Form.Label className="label-new">Идэвхтэй эсэх</Form.Label>
              <Form.Select
                aria-label="Default select example "
                value={newsId.is_active}
              >
                <option value={0}>Үгүй</option>
                <option value={1}>Тийм</option>
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              onChange={handleDraftChange}
            >
              <Form.Label className="label-new">Нийтлэх эсэх</Form.Label>
              <Form.Select
                aria-label="Default select example "
                value={newsId.publish}
              >
                <option value="Нийтлэх">Нийтлэх</option>
                <option value="Ноороглох">Ноороглох</option>
              </Form.Select>
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEdit}>
                Close
              </Button>
              <Button variant="primary" type="submit" onClick={handleEdit}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Устгах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {newsId.title} - гарчигтай мэдээллийг устгах гэж байна.
        </Modal.Body>
        <Modal.Footer className="footer-btn">
          {" "}
          <button className="yes">
            <img src="/trash-can.png" alt={newsId.id}  onClick={handleDelete}/>
          </button>
          <button className="yes" onClick={() => setSmShow(false)}>
            <img src="/cancel.png" alt="" />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
function WedoForm(role) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const [smShow, setSmShow] = useState(false);
  const [doId, setDoId] = useState({
    id: "",
    title: "",
    description: "",
    publish: "",
  });
  const user = role.role;

  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/wwd/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        setDoId(...data);
      });
  };
  const handleDelete = (e) => {
    e.preventDefault();

    const idToDelete = e.target.alt;
    axios
      .delete(`https://judilogy.mn/api/wwd/${idToDelete}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": authHeaders(),
        },
      })
      .then((res) => {
        setSmShow(false);
      });
  };

  const [wedo, setWedo] = useState([]);
  useEffect(() => {
    services
      .getWedo()
      .then((res) => res.json())
      .then((res) => setWedo(res));
  }, [wedo]);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [image, setImage] = useState("");
  const [draft, setDraft] = useState("Нийтлэх");
  const [is_active, setIs_active] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    data.append("description", desc);
    data.append("file", image);
    data.append("publish", draft);
    data.append("is_active", is_active);

    axios({
      method: "POST",
      url: "https://judilogy.mn/api/wwd",
      data: data,
      headers: {
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 201) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShow(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleTitleChange = (event) => {
    setDoId({ ...doId, title: event.target.value });
  };
  const handleDescChange = (event) => {
    setDoId({ ...doId, description: event.target.value });
  };
  const handleActiveChange = (event) => {
    setDoId({ ...doId, is_active: event.target.value });
  };
  const handleDraftChange = (event) => {
    setDoId({ ...doId, publish: event.target.value });
  };
  const handleEdit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", doId.title);
    data.append("description", doId.description);
    data.append("is_active", doId.is_active);
    data.append("publish", doId.publish);

    axios({
      method: "PUT",
      url: `https://judilogy.mn/api/wwd/${doId.id}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShowEdit(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <div className="p-1 m-4">
      <div className="text-end">
        <button className="p-2 m-2 new-btn" onClick={handleShow}>
          Шинэ
        </button>
      </div>
      <Table className="admin-table">
        <thead>
          <tr>
            <th>Гарчиг</th>
            <th>Дэлгэрэнгүй</th>
            <th>Үүсгэсэн огноо</th>
            <th>Засварласан огноо</th>
            <th>Хэрэглэгч</th>
            <th>Идэвхтэй эсэх</th>
            <th>Нийтлэх эсэх</th>
            <th>Зураг</th>
            <th>Удирдах</th>
          </tr>
        </thead>
        <tbody>
          {wedo.map((e, index) => (
            <tr key={index}>
              <td>{e.title}</td>
              <td>{e.description}</td>
              <td>{Moment(e.created_at).format("YYYY-MM-DD")}</td>
              <td>{Moment(e.updated_at).format("YYYY-MM-DD")}</td>
              <td>{e.created_by}</td>
              <td>{e.is_active == 1 ? "Тийм" : "Үгүй"}</td>
              <td>{e.publish}</td>
              <td className="wwd-img">
                <img
                  src={`https://judilogy.mn/api/wwd/image/${e.image_url}`}
                />
              </td>
              <td>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setSmShow(true);
                    showDetail(e.id);
                  }}
                >
                  <MdDelete className="table-icon" />
                </button>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setShowEdit(true);
                    showDetail(e.id);
                  }}
                >
                  <MdEdit className="table-icon" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Бид юу хийдэг вэ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} enctype="multipart/form-data">
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            >
              <Form.Label className="label-new">Гарчиг</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            >
              <Form.Label className="label-new">Дэлгэрэнгүй</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                style={{ height: "100px" }}
              />
            </Form.Group>

            <input
              type="file"
              name="file"
              onChange={(e) => setImage(e.target.files[0])}
              accept="image/png, image/jpeg"
            />

            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              value={draft}
              onChange={(e) => setDraft(e.target.value)}
            >
              <Form.Label className="label-new">Нийтлэх эсэх</Form.Label>
              <Form.Select aria-label="Default select example " required>
                <option value="Нийтлэх">Нийтлэх</option>
                <option value="Ноороглох">Ноороглох</option>
              </Form.Select>
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Засах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form enctype="multipart/form-data">
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Гарчиг</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={doId.title}
                onChange={handleTitleChange}
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Дэлгэрэнгүй</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                value={doId.description}
                onChange={handleDescChange}
                style={{ height: "100px" }}
              />
            </Form.Group>

            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              onChange={handleActiveChange}
            >
              <Form.Label className="label-new">Идэвхтэй эсэх</Form.Label>
              <Form.Select
                aria-label="Default select example "
                value={doId.is_active}
              >
                <option value={0}>Үгүй</option>
                <option value={1}>Тийм</option>
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              onChange={handleDraftChange}
            >
              <Form.Label className="label-new">Нийтлэх эсэх</Form.Label>
              <Form.Select
                aria-label="Default select example "
                value={doId.publish}
              >
                <option value="Нийтлэх">Нийтлэх</option>
                <option value="Ноороглох">Ноороглох</option>
              </Form.Select>
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEdit}>
                Close
              </Button>
              <Button variant="primary" type="submit" onClick={handleEdit}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Устгах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {doId.title} - гарчигтай мэдээллийг устгах гэж байна.
        </Modal.Body>
        <Modal.Footer className="footer-btn">
          {" "}
          <button className="yes" >
            <img src="/trash-can.png" alt={doId.id} onClick={handleDelete}/>
          </button>
          <button className="yes" onClick={() => setSmShow(false)}>
            <img src="/cancel.png" alt="" />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
function SudalgaaForm(role) {
  const user = role.role;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const [smShow, setSmShow] = useState(false);
  const [researchId, setResearchId] = useState({
    id: "",
    title: "",
    description: "",
    color: "",
    is_active: "",
  });

  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/research/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        setResearchId(...data);
      });
  };
  const handleDelete = (e) => {
    e.preventDefault();

    const idToDelete = e.target.alt;
    axios
      .delete(`https://judilogy.mn/api/research/${idToDelete}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": authHeaders(),
        },
      })
      .then((res) => {
        setSmShow(false);
      });
  };

  const [research, setResearch] = useState([]);
  useEffect(() => {
    services
      .getSudalgaa()
      .then((res) => res.json())
      .then((res) => setResearch(res));
  }, [research]);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [sudalgaa_type, setSudalgaa_type] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [is_active, setIs_active] = useState(1);
  const [color, setColor] = useState("");

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    data.append("description", desc);
    for (let file of selectedFiles) {
      data.append("files", file);
    }
    // data.append("filePdf", pdf);
    data.append("sudalgaa_type", sudalgaa_type);
    data.append("is_active", is_active);
    data.append("color", color);

    axios({
      method: "POST",
      url: "https://judilogy.mn/api/research",
      data: data,
      headers: {
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShow(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleTitleChange = (event) => {
    setResearchId({ ...researchId, title: event.target.value });
  };
  const handleDescChange = (event) => {
    setResearchId({ ...researchId, description: event.target.value });
  };
  const handleActiveChange = (event) => {
    setResearchId({ ...researchId, is_active: event.target.value });
  };
  const handleColorChange = (event) => {
    setResearchId({ ...researchId, color: event.target.value });
  };
  const handleEdit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", researchId.title);
    data.append("description", researchId.description);
    data.append("is_active", researchId.is_active);
    data.append("color", researchId.color);

    axios({
      method: "PUT",
      url: `https://judilogy.mn/api/research/${researchId.id}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShowEdit(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div className="p-1 m-4">
      <div className="text-end">
        <button className="p-2 m-2 new-btn" onClick={handleShow}>
          Шинэ
        </button>
      </div>
      <Table className="admin-table">
        <thead>
          <tr>
            <th>Гарчиг</th>
            <th>Дэлгэрэнгүй</th>
            <th>Төрөл</th>
            <th>Файл</th>
            <th>Зураг</th>
            <th>Идэвхтэй эсэх</th>
            <th>Өнгө</th>
            <th>Үүсгэсэн огноо</th>
            <th>Удирдах</th>
          </tr>
        </thead>
        <tbody>
          {research.map((e, index) => (
            <tr key={index}>
              <td>{e.title}</td>
              <td>{e.description}</td>
              <td>{e.sudalgaa_type}</td>
              <td>
                <a
                  href={`https://judilogy.mn/api/rs/download/${e.file}`}
                  target="_blank"
                >
                  {e.file}
                </a>
              </td>
              <td className="wwd-img">
                <img
                  src={`https://judilogy.mn/api/research/image/${e.image_url}`}
                />
              </td>
              <td>{e.is_active == 1 ? "Тийм" : "Үгүй"}</td>
              <td>{e.color}</td>
              <td>{Moment(e.created_at).format("YYYY-MM-DD")}</td>
              <td>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setSmShow(true);
                    showDetail(e.id);
                  }}
                >
                  <MdDelete className="table-icon" />
                </button>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setShowEdit(true);
                    showDetail(e.id);
                  }}
                >
                  <MdEdit className="table-icon" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Судалгаа</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} enctype="multipart/form-data">
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            >
              <Form.Label className="label-new">Гарчиг</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            >
              <Form.Label className="label-new">Дэлгэрэнгүй</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                style={{ height: "100px" }}
              />
            </Form.Group>

            <input
              type="file"
              name="files"
              multiple
              onChange={handleFileChange}
            />

            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              value={sudalgaa_type}
              onChange={(e) => setSudalgaa_type(e.target.value)}
            >
              <Form.Label className="label-new">Төрөл</Form.Label>
              <Form.Select aria-label="Default select example " required>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              value={color}
              onChange={(e) => setColor(e.target.value)}
            >
              <Form.Label className="label-new">Төрөл</Form.Label>
              <Form.Select aria-label="Default select example " required>
                <option value="">Сонгох</option>
                <option value="Red">Red</option>
                <option value="Green">Green</option>
                <option value="Blue">Blue</option>
              </Form.Select>
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Засах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form enctype="multipart/form-data">
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Гарчиг</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={researchId.title}
                onChange={handleTitleChange}
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Дэлгэрэнгүй</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                value={researchId.description}
                onChange={handleDescChange}
                style={{ height: "100px" }}
              />
            </Form.Group>

            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              onChange={handleActiveChange}
            >
              <Form.Label className="label-new">Идэвхтэй эсэх</Form.Label>
              <Form.Select
                aria-label="Default select example "
                value={researchId.is_active}
              >
                <option value={0}>Үгүй</option>
                <option value={1}>Тийм</option>
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              onChange={handleColorChange}
            >
              <Form.Label className="label-new">Өнгө</Form.Label>
              <Form.Select
                aria-label="Default select example "
                value={researchId.color}
              >
                <option value="">Сонгох</option>
                <option value="Green">Green</option>
                <option value="Red">Red</option>
                <option value="Blue">Blue</option>
              </Form.Select>
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEdit}>
                Close
              </Button>
              <Button variant="primary" type="submit" onClick={handleEdit}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Устгах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {researchId.title} - гарчигтай мэдээллийг устгах гэж байна.
        </Modal.Body>
        <Modal.Footer className="footer-btn">
          {" "}
          <button className="yes" >
            <img src="/trash-can.png" alt={researchId.id} onClick={handleDelete} />
          </button>
          <button className="yes" onClick={() => setSmShow(false)}>
            <img src="/cancel.png" alt="" />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MaterialForm(role) {
  const user = role.role;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const [smShow, setSmShow] = useState(false);
  const [docsId, setDocsId] = useState({
    id: "",
    title: "",
    description: "",
    docs_type: "",
    is_active: "",
  });

  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/doc/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        setDocsId(...data);
      });
  };
  const handleDelete = (e) => {
    e.preventDefault();

    const idToDelete = e.target.alt;
    axios
      .delete(`https://judilogy.mn/api/doc/${idToDelete}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": authHeaders(),
        },
      })
      .then((res) => {
        setSmShow(false);
      });
  };

  const [docs, setDocs] = useState([]);
  useEffect(() => {
    services
      .getDocs()
      .then((res) => res.json())
      .then((res) => setDocs(res));
  }, [docs]);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [docs_type, setDocs_type] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [is_active, setIs_active] = useState(1);
  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    data.append("description", desc);
    for (let file of selectedFiles) {
      data.append("files", file);
    }
    // data.append("filePdf", pdf);
    data.append("docs_type", docs_type);
    data.append("is_active", is_active);

    axios({
      method: "POST",
      url: "https://judilogy.mn/api/doc",
      data: data,
      headers: {
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShow(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleTitleChange = (event) => {
    setDocsId({ ...docsId, title: event.target.value });
  };
  const handleDescChange = (event) => {
    setDocsId({ ...docsId, description: event.target.value });
  };
  const handleActiveChange = (event) => {
    setDocsId({ ...docsId, is_active: event.target.value });
  };
  const handleDocTypeChange = (event) => {
    setDocsId({ ...docsId, docs_type: event.target.value });
  };
  const handleEdit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", docsId.title);
    data.append("description", docsId.description);
    data.append("is_active", docsId.is_active);
    data.append("docs_type", docsId.docs_type);

    axios({
      method: "PUT",
      url: `https://judilogy.mn/api/doc/${docsId.id}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShowEdit(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div className="p-1 m-4">
      <div className="text-end">
        <button className="p-2 m-2 new-btn" onClick={handleShow}>
          Шинэ
        </button>
      </div>
      <Table className="admin-table">
        <thead>
          <tr>
            <th>Гарчиг</th>
            <th>Дэлгэрэнгүй</th>
            <th>Төрөл</th>
            <th>Файл</th>
            <th>Зураг</th>
            <th>Идэвхтэй эсэх</th>
            <th>Үүсгэсэн огноо</th>
            <th>Удирдах</th>
          </tr>
        </thead>
        <tbody>
          {docs.map((e, index) => (
            <tr key={index}>
              <td>{e.title}</td>
              <td>{e.description}</td>
              <td>{e.docs_type}</td>
              <td>
                <a
                  href={`https://judilogy.mn/api/download/${e.filepdf}`}
                  target="_blank"
                >
                  {e.filepdf}
                </a>
              </td>
              <td className="mat-table-body">
                <img
                  className="mat-table"
                  src={`https://judilogy.mn/api/doc/image/${e.image_url}`}
                />
              </td>
              <td>{e.is_active == 1 ? "Тийм" : "Үгүй"}</td>
              <td>{Moment(e.created_at).format("YYYY-MM-DD")}</td>
              <td>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setSmShow(true);
                    showDetail(e.id);
                  }}
                >
                  <MdDelete className="table-icon" />
                </button>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setShowEdit(true);
                    showDetail(e.id);
                  }}
                >
                  <MdEdit className="table-icon" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Docs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} enctype="multipart/form-data">
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            >
              <Form.Label className="label-new">Гарчиг</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            >
              <Form.Label className="label-new">Дэлгэрэнгүй</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                style={{ height: "100px" }}
              />
            </Form.Group>

            <input
              type="file"
              name="files"
              multiple
              onChange={handleFileChange}
            />

            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              value={docs_type}
              onChange={(e) => setDocs_type(e.target.value)}
            >
              <Form.Label className="label-new">Төрөл</Form.Label>
              <Form.Select aria-label="Default select example " required>
                <option value="">Сонгох</option>
                <option value="Иргэний">Иргэний</option>
                <option value="Эрүүгийн">Эрүүгийн</option>
                <option value="Захиргааны">Захиргааны</option>
              </Form.Select>
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Засах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form enctype="multipart/form-data">
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Гарчиг</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={docsId.title}
                onChange={handleTitleChange}
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Дэлгэрэнгүй</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                value={docsId.description}
                onChange={handleDescChange}
                style={{ height: "100px" }}
              />
            </Form.Group>

            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              onChange={handleActiveChange}
            >
              <Form.Label className="label-new">Идэвхтэй эсэх</Form.Label>
              <Form.Select
                aria-label="Default select example "
                value={docsId.is_active}
              >
                <option value={0}>Үгүй</option>
                <option value={1}>Тийм</option>
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              onChange={handleDocTypeChange}
            >
              <Form.Label className="label-new">Нийтлэх эсэх</Form.Label>
              <Form.Select
                aria-label="Default select example "
                value={docsId.docs_type}
              >
                <option value="Иргэний">Иргэний</option>
                <option value="Эрүүгийн">Эрүүгийн</option>
                <option value="Захиргааны">Захиргааны</option>
              </Form.Select>
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEdit}>
                Close
              </Button>
              <Button variant="primary" type="submit" onClick={handleEdit}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Устгах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {docsId.title} - гарчигтай мэдээллийг устгах гэж байна.
        </Modal.Body>
        <Modal.Footer className="footer-btn">
          {" "}
          <button className="yes" >
            <img src="/trash-can.png" alt={docsId.id} onClick={handleDelete} />
          </button>
          <button className="yes" onClick={() => setSmShow(false)}>
            <img src="/cancel.png" alt="" />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function RegisterForm(role) {
  const user = role.role;
  const tableRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const [smShow, setSmShow] = useState(false);
  const [regId, setRegId] = useState({
    id: "",
    email: "",
  });
  const [register, setRegister] = useState([]);
  useEffect(() => {
    services
      .getRegister()
      .then((res) => res.json())
      .then((res) => setRegister(res));
  }, [register]);
  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/guest/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": authHeaders(),
        },
      })
      .then((res) => {
        const data = res.data;
        setRegId(...data);
      });
  };
  const handleDelete = (e) => {
    e.preventDefault();

    const idToDelete = e.target.alt;
    axios
      .delete(`https://judilogy.mn/api/guest/${idToDelete}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": authHeaders(),
        },
      })
      .then((res) => {
        setSmShow(false);
      });
  };

  const [email, setEmail] = useState("");
  const [is_active, setIs_active] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("email", email);
    data.append("is_active", is_active);

    axios({
      method: "POST",
      url: "https://judilogy.mn/api/guest",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShow(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleActiveChange = (event) => {
    setRegId({ ...regId, is_active: event.target.value });
  };
  const handleEdit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("is_active", regId.is_active);

    axios({
      method: "PUT",
      url: `https://judilogy.mn/api/guest/${regId.id}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShowEdit(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div className="p-1 m-4">
      <div className="text-end">
        <DownloadTableExcel
          filename="Registered_users"
          sheet="register"
          currentTableRef={tableRef.current}
          className="export"
        >
          <button className="p-2 m-2 new-btn"> Export excel </button>
        </DownloadTableExcel>
        <button className="p-2 m-2 new-btn" onClick={handleShow}>
          Шинэ
        </button>
      </div>
      <table className="w-100 border-1" ref={tableRef}>
        <thead>
          <tr className="border-1">
            <th>Бүртгүүлсэн имэйл</th>
            <th>Идэвхтэй эсэх</th>
            <th>Үүсгэсэн огноо</th>
            <th>Удирдах талбар</th>
          </tr>
        </thead>
        <tbody>
          {register.map((e, index) => (
            <tr key={index}>
              <td>{e.email}</td>
              <td>{e.is_active == 1 ? "Тийм" : "Үгүй"}</td>
              <td>{Moment(e.created_at).format("YYYY-MM-DD")}</td>
              <td>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setSmShow(true);
                    showDetail(e.id);
                  }}
                >
                  <MdDelete className="table-icon" />
                </button>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setShowEdit(true);
                    showDetail(e.id);
                  }}
                >
                  <MdEdit className="table-icon" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Бүртгэл</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} enctype="multipart/form-data">
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            >
              <Form.Label className="label-new">Имэйл</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Засах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form enctype="multipart/form-data">
            <p>"{regId.email}" хэрэглэгчийн төлвийг засах гэж байна.</p>
            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              onChange={handleActiveChange}
            >
              <Form.Label className="label-new">Идэвхтэй эсэх</Form.Label>
              <Form.Select
                aria-label="Default select example "
                value={regId.is_active}
              >
                <option value={0}>Үгүй</option>
                <option value={1}>Тийм</option>
              </Form.Select>
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEdit}>
                Close
              </Button>
              <Button variant="primary" type="submit" onClick={handleEdit}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Устгах</Modal.Title>
        </Modal.Header>
        <Modal.Body>{regId.email} - бүртгэлийг устгах гэж байна.</Modal.Body>
        <Modal.Footer className="footer-btn">
          {" "}
          <button className="yes">
            <img src="/trash-can.png" alt={regId.id} onClick={handleDelete} />
          </button>
          <button className="yes" onClick={() => setSmShow(false)}>
            <img src="/cancel.png" alt="" />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
function BarbenchForm(role) {
  const user = role.role;
  const tableRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const [smShow, setSmShow] = useState(false);
  const [benchId, setBenchId] = useState({
    id: "",
    title: "",
    description: "",
    is_active: "",
    color: "",
  });

  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/bench/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        setBenchId(...data);
      });
  };
  const handleDelete = (e) => {
    e.preventDefault();

    const idToDelete = e.target.alt;
    axios
      .delete(`https://judilogy.mn/api/bench/${idToDelete}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": authHeaders(),
        },
      })
      .then((res) => {
        setSmShow(false);
      });
  };

  const [bench, setBench] = useState([]);
  useEffect(() => {
    services
      .getBench()
      .then((res) => res.json())
      .then((res) => setBench(res));
  }, [bench]);

  const [benchReg, setBenchReg] = useState([]);
  useEffect(() => {
    services
      .getBenchReg()
      .then((res) => res.json())
      .then((res) => setBenchReg(res));
  }, [benchReg]);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [is_active, setIs_active] = useState(1);
  const [color, setColor] = useState("");
  const [fullscreen, setFullscreen] = useState(true);
  const [showFull, setShowFull] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    data.append("description", desc);
    data.append("is_active", is_active);
    data.append("color", color);

    axios({
      method: "POST",
      url: "https://judilogy.mn/api/bench",
      data: data,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShow(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleTitleChange = (event) => {
    setBenchId({ ...benchId, title: event.target.value });
  };
  const handleDescChange = (event) => {
    setBenchId({ ...benchId, description: event.target.value });
  };
  const handleColorChange = (event) => {
    setBenchId({ ...benchId, color: event.target.value });
  };
  const handleActiveChange = (event) => {
    setBenchId({ ...benchId, is_active: event.target.value });
  };
  const handleEdit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", benchId.title);
    data.append("description", benchId.description);
    data.append("is_active", benchId.is_active);
    data.append("color", benchId.color);
    axios({
      method: "PUT",
      url: `https://judilogy.mn/api/bench/${benchId.id}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShowEdit(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <div className="p-1 m-4">
      <div className="text-end">
        <button className="p-2 m-2 new-btn" onClick={handleShow}>
          Шинэ
        </button>
      </div>
      <Table className="admin-table">
        <thead>
          <tr>
            <th>Гарчиг</th>
            <th>Үүсгэсэн огноо</th>
            <th>Идэвхтэй эсэх</th>
            <th>Өнгө</th>
            <th>Удирдах</th>
          </tr>
        </thead>
        <tbody>
          {bench.map((e, index) => (
            <tr key={index}>
              <td>{e.title}</td>
              <td>{Moment(e.created_at).format("YYYY-MM-DD")}</td>
              <td>{e.is_active == 1 ? "Тийм" : "Үгүй"}</td>
              <td>{e.color}</td>
              <td>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setSmShow(true);
                    showDetail(e.id);
                  }}
                >
                  <MdDelete className="table-icon" />
                </button>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setShowEdit(true);
                    showDetail(e.id);
                  }}
                >
                  <MdEdit className="table-icon" />
                </button>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setShowFull(true);
                    showDetail(e.id);
                  }}
                >
                  <FaRegUserCircle className="table-icon" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bar&Bench</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} enctype="multipart/form-data">
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            >
              <Form.Label className="label-new">Гарчиг</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            >
              <Form.Label className="label-new">Дэлгэрэнгүй</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                style={{ height: "100px" }}
              />
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              value={is_active}
              onChange={(e) => setIs_active(e.target.value)}
            >
              <Form.Label className="label-new">Идэвхтэй эсэх</Form.Label>
              <Form.Select aria-label="Default select example ">
                <option value="">Сонгох</option>
                <option value={0}>Үгүй</option>
                <option value={1}>Тийм</option>
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              value={color}
              onChange={(e) => setColor(e.target.value)}
            >
              <Form.Label className="label-new">Өнгө</Form.Label>
              <Form.Select aria-label="Default select example ">
                <option value="">сонгох</option>
                <option value="Green">Green</option>
                <option value="Red">Red</option>
                <option value="Orange">Orange</option>
                <option value="Blue">Blue</option>
              </Form.Select>
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Засах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form enctype="multipart/form-data">
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Гарчиг</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={benchId.title}
                onChange={handleTitleChange}
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Дэлгэрэнгүй</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                value={benchId.description}
                onChange={handleDescChange}
                style={{ height: "100px" }}
              />
            </Form.Group>

            <Form.Group className="mb-1" controlId="formGridCompany">
              <Form.Label className="label-new">Идэвхтэй эсэх</Form.Label>
              <Form.Select
                aria-label="Default select example "
                value={benchId.is_active}
                onChange={handleActiveChange}
              >
                <option value="">сонгох</option>
                <option value={0}>Үгүй</option>
                <option value={1}>Тийм</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-1" controlId="formGridCompany">
              <Form.Label className="label-new">Өнгө</Form.Label>
              <Form.Select
                aria-label="Default select example "
                value={benchId.color}
                onChange={handleColorChange}
              >
                <option value="">сонгох</option>
                <option value="Green">Green</option>
                <option value="Red">Red</option>
                <option value="Orange">Orange</option>
                <option value="Blue">Blue</option>
              </Form.Select>
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEdit}>
                Close
              </Button>
              <Button variant="primary" type="submit" onClick={handleEdit}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Устгах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {benchId.title} - гарчигтай мэдээллийг устгах гэж байна.
        </Modal.Body>
        <Modal.Footer className="footer-btn">
          {" "}
          <button className="yes" >
            <img src="/trash-can.png" alt={benchId.id} onClick={handleDelete}/>
          </button>
          <button className="yes" onClick={() => setSmShow(false)}>
            <img src="/cancel.png" alt="" />
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showFull}
        fullscreen={fullscreen}
        onHide={() => setShowFull(false)}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {benchId.title} хэлэлцүүлэгт бүртгүүлсэн хүмүүсийн жагсаалт
          </Modal.Title>
        </Modal.Header>
        <div>
          {" "}
          <DownloadTableExcel
            filename="Registered_users"
            sheet="register"
            currentTableRef={tableRef.current}
            className="export"
          >
            <button className="p-2 m-2 new-btn"> Export excel </button>
          </DownloadTableExcel>
        </div>
        <Modal.Body>
          <table className="w-100 border-1" ref={tableRef}>
            <thead>
              <tr>
                <th>#</th>
                <th>Нэр</th>
                <th>Имэйл</th>
                <th>Утас</th>
              </tr>
            </thead>

            <tbody>
              {benchReg
                .filter((n) => n.barbench_id === benchId.id)
                .map((e) => (
                  <tr>
                    <td>{e.id}</td>
                    <td>{e.name}</td>
                    <td>{e.email}</td>
                    <td>{e.phone}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </div>
  );
}
function UserForm(role) {
  const user = role.role;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [admin, setAdmin] = useState([]);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    userService
      .getAllUser()
      .then((res) => res.json())
      .then((res) => setAdmin(res));
  }, [admin]);
  const [smShow, setSmShow] = useState(false);
  const [adminId, setAdminId] = useState({
    id: "",
    username: "",
    email: "",
    password: "",
  });
  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/user/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": authHeaders(),
        },
      })
      .then((res) => {
        const data = res.data;
        setAdminId(...data);
      });
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const idToDelete = e.target.alt;
    axios
      .delete(`https://judilogy.mn/api/user/${idToDelete}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": authHeaders(),
        },
      })
      .then((res) => {
        setSmShow(false);
      });
  };

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handlePasswordChange = (event) => {
    setAdminId({ ...adminId, password: event.target.value });
  };
  const handleEdit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("password", adminId.password);
    axios({
      method: "PUT",
      url: `https://judilogy.mn/api/user/${adminId.id}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShowEdit(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("email", email);
    data.append("username", username);
    data.append("password", password);

    axios({
      method: "POST",
      url: "https://judilogy.mn/api/user/register",
      data: data,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShow(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <div className="p-1 m-4">
      <div className="text-end">
        <button className="p-2 m-2 new-btn" onClick={handleShow}>
          Шинэ
        </button>
      </div>
      <table className="w-100 border-1">
        <thead>
          <tr className="border-1">
            <th>Нэвтрэх нэр</th>
            <th>Имэйл хаяг</th>
            <th>Үүсгэсэн огноо</th>
            <th>Удирдах талбар</th>
          </tr>
        </thead>
        <tbody>
          {admin.map((e, index) => (
            <tr key={index}>
              <td className="text-start">{e.username}</td>
              <td className="text-start">{e.email}</td>
              <td className="text-start">
                {Moment(e.created_at).format("YYYY-MM-DD")}
              </td>
              <td>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setSmShow(true);
                    showDetail(e.id);
                  }}
                >
                  <MdDelete className="table-icon" />
                </button>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setShowEdit(true);
                    showDetail(e.id);
                  }}
                >
                  <MdEdit className="table-icon" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Шинэ хэрэглэгч бүртгэх</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} enctype="multipart/form-data">
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            >
              <Form.Label className="label-new">Email</Form.Label>
              <Form.Control type="email" placeholder="" />
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            >
              <Form.Label className="label-new">Username</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            >
              <Form.Label className="label-new">Password</Form.Label>
              <Form.Control type="password" placeholder="" />
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Устгах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {adminId.username} - хэрэглэгчийг устгах гэж байна.
        </Modal.Body>
        <Modal.Footer className="footer-btn">
          {" "}
          <button className="yes" >
            <img src="/trash-can.png" alt={adminId.id} onClick={handleDelete}/>
          </button>
          <button className="yes" onClick={() => setSmShow(false)}>
            <img src="/cancel.png" alt="" />
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {adminId.username} хэрэглэгчийн нууц үгийг солих
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form enctype="multipart/form-data">
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Password</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={adminId.password}
                onChange={handlePasswordChange}
              />
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEdit}>
                Close
              </Button>
              <Button variant="primary" type="submit" onClick={handleEdit}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
function PodcastForm(role) {
  const user = role.role;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const [smShow, setSmShow] = useState(false);
  const [podcastId, setPodcastId] = useState({
    id: "",
    title: "",
    description: "",
    publish: "",
    link: "",
  });

  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/podcast/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        setPodcastId(...data);
      });
  };
  const handleDelete = (e) => {
    e.preventDefault();

    const idToDelete = e.target.alt;
    axios
      .delete(`https://judilogy.mn/api/podcast/${idToDelete}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": authHeaders(),
        },
      })
      .then((res) => {
        setSmShow(false);
      });
  };

  const [podcast, setPodcast] = useState([]);
  useEffect(() => {
    services
      .getPodcast()
      .then((res) => res.json())
      .then((res) => setPodcast(res));
  }, [podcast]);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [link, setLink] = useState("");
  const [draft, setDraft] = useState("Нийтлэх");
  const [is_active, setIs_active] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    data.append("description", desc);
    data.append("link", link);
    data.append("publish", draft);
    data.append("is_active", is_active);

    axios({
      method: "POST",
      url: "https://judilogy.mn/api/podcast",
      data: data,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShow(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleTitleChange = (event) => {
    setPodcastId({ ...podcastId, title: event.target.value });
  };
  const handleDescChange = (event) => {
    setPodcastId({ ...podcastId, description: event.target.value });
  };
  const handleActiveChange = (event) => {
    setPodcastId({ ...podcastId, is_active: event.target.value });
  };
  const handleDraftChange = (event) => {
    setPodcastId({ ...podcastId, publish: event.target.value });
  };
  const handleLinkChange = (event) => {
    setPodcastId({ ...podcastId, link: event.target.value });
  };
  const handleEdit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", podcastId.title);
    data.append("description", podcastId.description);
    data.append("is_active", podcastId.is_active);
    data.append("publish", podcastId.publish);
    data.append("link", podcastId.link);
    axios({
      method: "PUT",
      url: `https://judilogy.mn/api/podcast/${podcastId.id}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeaders(),
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Амжилттай илгээгдлээ!", {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setShowEdit(false);
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <div className="p-1 m-4">
      <div className="text-end">
        <button className="p-2 m-2 new-btn" onClick={handleShow}>
          Шинэ
        </button>
      </div>
      <Table className="admin-table">
        <thead>
          <tr>
            <th>Гарчиг</th>
            <th>Дэлгэрэнгүй</th>
            <th>Үүсгэсэн огноо</th>
            <th>Идэвхтэй эсэх</th>
            <th>Нийтлэх эсэх</th>
            <th>Link</th>
            <th>Удирдах</th>
          </tr>
        </thead>
        <tbody>
          {podcast.map((e, index) => (
            <tr key={index}>
              <td>{e.title}</td>
              <td>{e.description}</td>
              <td>{Moment(e.created_at).format("YYYY-MM-DD")}</td>
              <td>{e.is_active == 1 ? "Тийм" : "Үгүй"}</td>
              <td>{e.publish}</td>
              <td className="link-td">
                <div dangerouslySetInnerHTML={{ __html: e.link }}></div>
              </td>

              <td>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setSmShow(true);
                    showDetail(e.id);
                  }}
                >
                  <MdDelete className="table-icon" />
                </button>
                <button
                  className="table-btn p-1"
                  onClick={() => {
                    setShowEdit(true);
                    showDetail(e.id);
                  }}
                >
                  <MdEdit className="table-icon" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Мэдээ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} enctype="multipart/form-data">
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            >
              <Form.Label className="label-new">Гарчиг</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            >
              <Form.Label className="label-new">Дэлгэрэнгүй</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                style={{ height: "100px" }}
              />
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formBasicEmail"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            >
              <Form.Label className="label-new">Линк</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                style={{ height: "100px" }}
              />
            </Form.Group>

            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              value={draft}
              onChange={(e) => setDraft(e.target.value)}
            >
              <Form.Label className="label-new">Нийтлэх эсэх</Form.Label>
              <Form.Select aria-label="Default select example " required>
                <option value="Нийтлэх">Нийтлэх</option>
                <option value="Ноороглох">Ноороглох</option>
              </Form.Select>
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Засах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form enctype="multipart/form-data">
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Гарчиг</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={podcastId.title}
                onChange={handleTitleChange}
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Дэлгэрэнгүй</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                value={podcastId.description}
                onChange={handleDescChange}
                style={{ height: "100px" }}
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="label-new">Линк</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder=""
                value={podcastId.link}
                onChange={handleLinkChange}
                style={{ height: "100px" }}
              />
            </Form.Group>

            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              onChange={handleActiveChange}
            >
              <Form.Label className="label-new">Идэвхтэй эсэх</Form.Label>
              <Form.Select
                aria-label="Default select example "
                value={podcastId.is_active}
              >
                <option value={0}>Үгүй</option>
                <option value={1}>Тийм</option>
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-1"
              controlId="formGridCompany"
              onChange={handleDraftChange}
            >
              <Form.Label className="label-new">Нийтлэх эсэх</Form.Label>
              <Form.Select
                aria-label="Default select example "
                value={podcastId.publish}
              >
                <option value="Нийтлэх">Нийтлэх</option>
                <option value="Ноороглох">Ноороглох</option>
              </Form.Select>
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEdit}>
                Close
              </Button>
              <Button variant="primary" type="submit" onClick={handleEdit}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Устгах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {podcastId.title} - гарчигтай мэдээллийг устгах гэж байна.
        </Modal.Body>
        <Modal.Footer className="footer-btn">
          {" "}
          <button className="yes">
            <img src="/trash-can.png" alt={podcastId.id}  onClick={handleDelete} />
          </button>
          <button className="yes" onClick={() => setSmShow(false)}>
            <img src="/cancel.png" alt="" />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Admin;
