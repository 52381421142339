import { useEffect, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import Slider from "react-slick";
import { services } from "../services/services";
import axios from "axios";
function Wedo(props) {
  const settings = {
    className: "center",
    dots: true,
    centerMode: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    speed: 500,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  };
  const [show, setShow] = useState(false);
  const [wedo, setWedo] = useState([]);
  useEffect(() => {
    services
      .getWedo()
      .then((res) => res.json())
      .then((res) => setWedo(res));
  }, []);
  console.log(wedo);
  const [modal, setModal] = useState({
    id: "",
    title: "",
    description: "",
    image_url: "",
  });
  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/wwd/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        setModal(...data);
      });
  };
  console.log(modal);
  return (
    <div>
      <Container>
        <h3 className="title-section mb-4">Бид юу хийдэг вэ?</h3>
        {/* What is the best way to make 5 cards look? */}
        {wedo
          .filter((n) => (n.is_active = 1))
          .map((e, index) => (
            <div className="flip" onClick={(n) => {
              setShow(true);
              showDetail(e.id);
            }}>
              <div
                className="front"
                
                style={{
                  backgroundImage:  `linear-gradient(to bottom, rgba(55, 42, 72, 0.536), rgba(31, 26, 50, 0.711)), url(https://judilogy.mn/api/wwd/image/${e.image_url})`,
                }}
              >
                <h3 className="text-shadow text-center">{e.title}</h3>
              </div>
              <div className="back">
              
                <p>
                  {e.description}
                </p>
              </div>
            </div>
          ))}

        {/* <div className="wedo d-flex justify-content-between">
          {wedo
            .filter((n) => (n.is_active = 1))
            .map((e, index) => (
              <article
                class="card-wedo"
                key={index}
                onClick={(n) => {
                  setShow(true);
                  showDetail(e.id);
                }}
              >
                <img
                  class="card__background"
                  src={`https://judilogy.mn/api/wwd/image/${e.image_url}`}
                  alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
                  width="1920"
                  height="2193"
                />
                <div class="card__content | flow">
                  <div class="card__content--container | flow">
                    <h2 class="card__title">{e.title}</h2>
                    <p class="card__description">{e.description}</p>
                  </div>
                </div>
              </article>
            ))}
        </div> */}

        {/* <Slider {...settings}>
          {wedo.filter((n) => (n.is_active = 1)).map((e, index) => (
            <Card
              key={index}
              className="wedo-card"
              onClick={(n) => {
                setShow(true);
                showDetail(e.id);
              }}
            >
              <Card.Img
                variant="top"
                src={`https://judilogy.mn/api/wwd/image/${e.image_url}`}
              />
              <Card.Body>
                <Card.Title className="wedo-title">{e.title}</Card.Title>
                <Card.Text className="wedo-text">{e.description}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Slider> */}
        <Modal show={show} onHide={() => setShow(false)} centered>
          <Card className="wedo-card">
            <Row>
              <Col>
                <Card.Img
                  className="wedo-image"
                  variant="top"
                  src={`https://judilogy.mn/api/wwd/image/${modal.image_url}`}
                />
              </Col>
              <Col className="d-flex align-items-center wedo-modal-text">
                <Card.Body>
                  <Card.Title className="wedo-title-modal">
                    {modal.title}
                  </Card.Title>
                  <Card.Text className="wedo-text-modal">
                    {modal.description}
                  </Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Modal>
      </Container>
    </div>
  );
}

export default Wedo;
