import React, { useEffect, useState } from "react";
import Header from "./Header";
import {
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Modal,
  Navbar,
} from "react-bootstrap";
import Footer from "./Footer";
import { useLocation, NavLink } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import { services } from "../services/services";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

function AllDocs(props) {
  const [searchDocs, setSearchDocs] = useState("");
  const location = useLocation();
  const path = location.pathname;
  const { state } = location;
  const data_type1 = state.from;
  const [docs, setDocs] = useState([]);
  useEffect(() => {
    services
      .getDocs()
      .then((res) => res.json())
      .then((res) => setDocs(res));
  }, []);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [docId, setDocId] = useState({
    id: "",
    title: "",
    filepdf: "",
  });

  const showDetail = (id) => {
    axios
      .get(`https://judilogy.mn/api/doc/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        setDocId(...data);
      });
  };
  useEffect(() => {
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    window.addEventListener("contextmenu", disableRightClick);

    return () => {
      window.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);
  useEffect(() => {
    const handleBeforeCapture = (e) => {
      e.preventDefault();
      alert("Taking screenshots is not allowed.");
    };

    window.addEventListener("beforeprint", handleBeforeCapture);

    return () => {
      window.removeEventListener("beforeprint", handleBeforeCapture);
    };
  }, []);
  return (
    <div>
    <div className="header-bg">
        {" "}
        <Header />
      </div>
      <Container>
        <ul className="breadcrumb">
          <a href="/" className="breadcrumb__item breadcrumb__item-firstChild">
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">Нүүр</span>
              </span>
            </li>
          </a>

          <li
            className={
              path == "/alldocs" ? "selected_title" : "breadcrumb__item"
            }
          >
            <span className="breadcrumb__inner">
              <span className="breadcrumb__title">
                Хэргийн мэдээллийн хуудас
              </span>
            </span>
          </li>
          <NavLink
            to={{ pathname: "/khereg" }}
            state={{
              from: data_type1,
            }}
            className="breadcrumb__item"
          >
            <li>
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">
                Иргэний хэрэг
                </span>
              </span>
            </li>
          </NavLink>
          <li className="breadcrumb__item">
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">
                Захиргааны хэрэг
                </span>
              </span>
            </li>
            <li className="breadcrumb__item">
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title">
                Эрүүгийн хэрэг
                </span>
              </span>
            </li>
        </ul>

        <InputGroup>
          <Form.Control
            className="search"
            placeholder="Search"
            aria-label="Username"
            aria-describedby="basic-addon1"
            type="search"
            onChange={(e) => {
              setSearchDocs(e.target.value);
            }}
          />
          <InputGroup.Text id="basic-addon1">
            <IoSearch />
          </InputGroup.Text>
        </InputGroup>
        <div>
          {docs.filter((val) => {
                if (searchDocs === "") {
                  return val;
                } else if (
                  val.title
                    .toLowerCase()
                    .includes(searchDocs.toLowerCase()) ||
                  val.description
                    .toLowerCase()
                    .includes(searchDocs.toLowerCase()) ||
                  val.docs_type
                    .toLowerCase()
                    .includes(searchDocs.toLowerCase())
                ) {
                  return val;
                }
              }).map((e) => (
            <Card className="alldoc-card">
              <Row>
                <Col sm={4}>
                  <Card.Img
                    className="docs-image"
                    variant="top"
                    src={`https://judilogy.mn/api/doc/image/${e.image_url}`}
                  />
                </Col>
                <Col sm={8} className="d-flex doc-modal-text">
                  <Card.Body className="ff">
                    <Row>
                      {" "}
                      <Card.Title className="docs-title-modal">
                        {e.title}
                      </Card.Title>
                    </Row>

                    <Row>
                      {" "}
                      <Card.Text className="docs-text-modal">
                        {e.description}
                      </Card.Text>
                    </Row>

                    <Row className="">
                      <Col>
                        <p className="text-start doc-t">{e.docs_type} хэрэг</p>
                      </Col>
                      <Col>
                        <p
                          className="text-end doc-t"
                          onClick={() => {
                            setShow(true);
                            showDetail(e.id);
                          }}
                        >
                          Унших
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          ))}
        </div>
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <Navbar.Brand href="/">
                <img src="/judilogy_logo.png" alt="" className="logo" />
              </Navbar.Brand>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pdf-view">
            <div className="pdf-div">
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <Document
                file={`https://judilogy.mn/api/files/docs/${docId.filepdf}`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page) => {
                    return (
                      <Page
                        pageNumber={page}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                      />
                    );
                  })}
              </Document>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
      <Footer />
    </div>
  );
}

export default AllDocs;
