import React from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { userService } from "../services/userService";
import { toast } from "react-toastify";
function Login() {
    const navigate = useNavigate();
    
    function handleSubmit(e) {
    e.preventDefault();
    console.log(e.target[0].value)
    userService
      .loginUser({
        email: e.target[0].value,
        password: e.target[1].value,
      })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          localStorage.setItem("user", JSON.stringify(data.data));
          localStorage.setItem("token", JSON.stringify(data.token));
          redirect();
        } else {
          toast.error("Нууц үг эсвэл email хаяг буруу байна!", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast.error("Server-тэй холбогдоход алдаа гарлаа!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }
  function redirect() {
    navigate("/admin", { state: "ok" });
   
  }


  return (
    <div className="login-form d-flex justify-content-center align-items-center">
      <div className="login-user">
        <div className="login-text">
          <div className="login-text-icon"></div>
          <h4>Нэвтрэх</h4>
        </div>

        <Form action="" onSubmit={handleSubmit}>
          <Form.Group className="mb-3 login-label" controlId="formBasicEmail">
            <Form.Label>И-Мэйл хаяг</Form.Label>
            <Form.Control
              type="email"
              placeholder="И-мэйл хаягаа оруулна уу."
              autoFocus
            />
            
          </Form.Group>

          <Form.Group className="mb-3 login-label" controlId="formBasicPassword">
            <Form.Label>Нууц үг</Form.Label>
            <Form.Control type="password" placeholder="Нууц үгээ оруулна уу." />
          </Form.Group>
          <div className="text-center mt-5">
            <Button variant="primary" type="submit" className="login-btn">
              Нэвтрэх
            </Button>
          </div>
        </Form>
     
      </div>
    </div>
  );
}

export default Login;
